
import { defineComponent, ref, onMounted, computed } from 'vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import router from "@/router";
import routerService from '@/services/routerService'
import Breadcrumbs from '@/components/ui/Breadcrumbs.vue';
import { useStore } from '@/store';
import {useRoute, useRouter} from "vue-router";
import { roles } from '@/services/roles/serviceRoles';

export default defineComponent({
  name: 'CarsSearchPage',
  components: {
    Breadcrumbs,
    SearchDropdown,
    BaseIcon
  },
  data: () => ({
    menu: [
      {key: 'actual', value: 'Актуальные'},
      {key: 'blocked', value: 'Заблокированные'},
      {key: 'all', value: 'Все'},
    ] as object,
    activeMenu: 'actual' as any
  }),
  computed: {
    userViewBlockedUnblockedVehicle(): any {
      return this.$store.getters.getAllPermissions.blocked_vehicles[this.$store.getters.getUserRole]
    },
    checkActiveMenu(): any {
      return this.$route.query.vehicleState || 'actual'
    },
    getPath() {
      return (type: string): any => {
        return routerService.changeQuery('vehicleType', type, this.$route.query, this.$route.path, 'brands')
      }
    },
    getCurrentCrumbs(): any {
      let crumbName = 'Поиск'
      if(this.$route.query.vehicleState) {
        crumbName = this.isBlocked
          ? 'Заблокированные'
          : 'Актуальные'
      }

      return [{
        name: crumbName,
        link: this.$route.fullPath
      }]
    },
    isBlocked(): any {
      return this.$route.query.vehicleState === 'blocked'
        ? true
        : this.$route.query.vehicleState === 'actual'
          ? false
          : 'ALL'
    }
  },
  methods: {
    update(item: any) {
      if (!!item) {
        if (item && item.brand) {
          router.push(`/cars/brands/${item.id}/models?${this.$route.query.vehicleState ? 'vehicleState=' + this.$route.query.vehicleState + '&' : ''}vehicleType=all_categories`)
        } else if (item && item.id) {
          this.$router.push({
            name: 'vehicle',
            params: { carId: item.id }
          })
        }
      }
    },
    setActiveMenu(option: any) {
      routerService.setQuery('vehicleState', option.key, this.$route.query, this.$router)
    }
  },
  setup() {
    const store = useStore();
    const types = computed(() => store.getters.getVehicleTypes);

    function goToArticle(item: any) {
      router.push({name: 'article', params: {id: item.articleId}})
    }

    onMounted(() => {
      let loggedUser = localStorage.getItem('loggedUser');
      let cid = localStorage.getItem('cid');
      if (loggedUser && cid) {
      } else {
        router.push('login')
      }
    });

    return { types, goToArticle }
  }
})
